<template>
  <div @mouseleave="onMouseLeave">
    <nav>
      <div class="container">
        <ul
          class="flex items-center gap-x-12 overflow-auto snap-x snap-mandatory styled-scrollbars mx-auto w-fit"
        >
          <li v-for="link in navigation" :key="link.id" class="flex-shrink-0">
            <component
              :is="getComponentType(link)"
              :id="
                link.url.includes('btn-id-') ? extractButtonId(link.url) : null
              "
              :to="
                !link.is_title && !link.url.includes('btn-id-')
                  ? localePath(link.url)
                  : null
              "
              class="inline-flex ui-text-sm uppercase"
              :class="[
                !link.is_title && 'cursor-pointer hover:text-crimson',
                menuOpened && activeMenu?.id === link.id && 'text-crimson',
                Number(activeLink?.id) === link.id ? 'text-crimson' : '',
              ]"
              :data-e2e-item="!link.is_title ? 'navigation' : null"
              @mouseenter="setActiveMenu(link)"
            >
              {{ link?.currentTranslation?.title }}
            </component>
          </li>
        </ul>
      </div>
    </nav>
    <teleport to="body">
      <transition
        enter-active-class="transition duration-150 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-100 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="menuOpened"
          class="fixed left-0 right-0 top-[calc(var(--header-height)+var(--discount-compaign-visible-height))] z-[99999] max-h-[calc(100dvh-(var(--header-height)+var(--discount-compaign-visible-height)))] overflow-auto"
          @mouseenter="setActiveMenu(activeMenu)"
          @mouseleave="onMouseLeave"
        >
          <div class="flex w-full bg-white pb-10 pt-7.5">
            <div class="container">
              <div
                class="grid grid-cols-[repeat(6,minmax(100px,max-content))] gap-13.5 3xl:grid-cols-[repeat(8,minmax(100px,max-content))]"
              >
                <div v-for="link in activeMenu?.children" :key="link.id">
                  <component
                    :is="getComponentType(link)"
                    :id="
                      link.url.includes('btn-id-')
                        ? extractButtonId(link.url)
                        : null
                    "
                    :to="
                      !link.is_title && !link.url.includes('btn-id-')
                        ? localePath(link.url)
                        : null
                    "
                    class="mb-5 inline-flex ui-text-sm uppercase text-crimson"
                    :class="[
                      !link.is_title &&
                        'cursor-pointer underline-offset-4 hover:underline',
                    ]"
                    :data-e2e-item="!link.is_title ? 'navigation' : null"
                  >
                    <span v-html="link?.currentTranslation?.title" />
                  </component>
                  <ul class="space-y-3.5">
                    <li
                      v-for="link2 in link.children"
                      :key="link2.id"
                      class="ui-text-sm"
                    >
                      <component
                        :is="getComponentType(link2)"
                        :id="
                          link2.url.includes('btn-id-')
                            ? extractButtonId(link2.url)
                            : null
                        "
                        :to="
                          !link2.is_title && !link2.url.includes('btn-id-')
                            ? localePath(link2.url)
                            : null
                        "
                        class="inline-flex items-center gap-1.5"
                        :class="[
                          !link2.is_title &&
                            'cursor-pointer underline-offset-4 hover:underline',
                          Number(activeLink?.id) === link2.id
                            ? 'font-bold before:mr-1 before:inline-block before:size-2 before:rounded-full before:bg-crimson'
                            : '',
                        ]"
                        :data-e2e-item="!link2.is_title ? 'navigation' : null"
                      >
                        <img
                          v-if="link2.image || link2.icon"
                          class="h-4 w-4 object-contain"
                          :src="link2.image || link2.icon"
                          :alt="link2?.currentTranslation?.title"
                        />
                        <span v-html="link2?.currentTranslation?.title" />
                      </component>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { Float } from "@headlessui-float/vue";
import { NavMenuItem } from "ecom-types";
import { NuxtLink } from "#components";

const { headerMenu } = useGeneralStore();

const localePath = useLocalePathPolyfill();

const activeMenu = ref<TreeItem<NavMenuItem> | null>(null);
let timeout: ReturnType<typeof setTimeout>;

const navigation = computed(() => {
  return toTree(headerMenu);
});

const { findBreadcrumb } = useBreadcrumbs();

const route = useRoute();
const menuItemSpace = 36;

watch(
  () => route.fullPath,
  () => {
    setActiveMenu(null);
  },
  {
    immediate: true,
  },
);

const menuOpened = computed(() => {
  return activeMenu.value && activeMenu.value.children?.length;
});

const activeLink = computed(() => {
  const breadcrumbs = findBreadcrumb(navigation.value, route);
  return breadcrumbs[breadcrumbs.length - 1];
});

function onMouseLeave() {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    setActiveMenu(null);
  }, 350);
}

function setActiveMenu(item: TreeItem<NavMenuItem> | null) {
  clearTimeout(timeout);
  activeMenu.value = item;
}

function getComponentType(link: NavMenuItem) {
  if (link.is_title) {
    return "div";
  } else if (link.url.includes("btn-id-")) {
    return "button";
  } else {
    return NuxtLink;
  }
}
</script>
